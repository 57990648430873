.ant-modal-header {
  /* background-color: green !important; */
}
.modal-background-color {
  background-color: red;
}
.special_modal .modal-content {
  background-color: #000;
  color: #fff;
}
.modalStyle .ant-modal-header {
  border-radius: 20px 20px 0 0;
  background-color: red;
}
