.employee-card {
  padding: 1.25rem;
}

@media only screen and (max-width: 768px) {
  .text-container {
    margin-left: 0.75rem;
  }

  .text-container:lang(ar) {
    margin-right: 1.125rem;
    margin-left: 0;
  }

  .employee-card {
    padding: 1.25rem;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1265px) {
  .sp-card-grid {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(auto-fill, 120px);
  }

  .employee-card {
    padding: 1.25rem;
  }

  .text-container {
    margin-left: 0.75rem;
  }

  .text-container:lang(ar) {
    margin-right: 0.938rem;
    margin-left: 0;
  }
}
.desc-container {
  width: 50%;
  border-right: 1px solid gray;
}
/* .ant-select-selection-item-disabled {
  background-color: #3db0f7 !important;
  border-radius: 4px !important;
} */
