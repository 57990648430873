body {
  margin: 0;
  font-family: "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Segoe UI", "Roboto", "Helvetica Neue";
}

.app-selector-style {
  background: rgb(0, 175, 240);
  padding: 10px 14px;
  color: rgb(255, 255, 255);
  border-radius: 4px;
}
