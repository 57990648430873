.main-grid-row {
  display: grid;
  grid-template-columns: 2fr 6fr 1fr;
}

.main-add-new {
  display: grid;
  grid-template-columns: 2fr 6fr 1fr;
}

.staffing-grid-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  border: 1px solid #92a5b3;
  box-shadow: 0 0 rgba(0, 0, 0, 0), 0 0 rgba(0, 0, 0, 0),
    0 16px 32px -3px rgba(25, 85, 128, 0.1),
    0 4px 6px -2px rgba(25, 85, 128, 0.2);
  color: #0057ad;
  min-width: 140px;
  height: 140px;
}

.left-start-col {
  background-image: linear-gradient(
    89.2deg,
    rgb(190 230 255) 22.3%,
    rgb(141 208 243) 84.1%
  );
  padding: 0rem 1rem;
  min-width: 200px;
  width: 100%;
}

.staffing-grid-box .card {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  justify-content: start;
  align-items: center;
  width: 100%;
  padding: 2%;
  gap: 2%;
  cursor: pointer;
}

.add-new-employee-sheet {
  background-color: #ebf7ff;
  padding: 1rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-new-employee-sheet button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  width: 100%;
  gap: 2rem;
  border: 1px dashed #0057ad;
  border-radius: 4px;
}

.active-col {
  background-color: #bee5ff;
  color: #000;
}

.main-datasheet {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 2fr 6fr 1fr;
}

.sheet-main {
  overflow-x: scroll;
}

.main-sheet-row {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: repeat(16, 1fr);
}

.mand-asgn-emp-cont {
  width: 100%;
  margin: 12px;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
