.login-bg {
    background-image: url(../../Images/login-vects.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.divider {
    display: flex;
    outline: none;
    color: #5c5c5c !important;
}

.wrapper-2-cs {
    width: 380px;
    max-width: 374px;
}

.divider:before,
.divider:after {
    content: "";
    flex: 1;
}

.line {
    align-items: center;
    margin: 1em -1em;
}

.line:before,
.line:after {
    height: 1px;
    margin: 0 1em;
}


.one-line:before,
.one-line:after {
    background: #b8b8b8;
}

.social-btn {
    border: 1px solid #a1a9b3;
    border-radius: 4px;
    background-color: white;
    box-shadow: rgb(0 0 0 / 10%) 0 1px 2px;
    transition: .2s all;
    color: #999 !important;
}

.social-btn:hover {
    box-shadow: rgb(0 0 0 / 30%) 0 1px 3px;
}

.tagline-login {
    position: absolute;
    top: 36px;
    left: 36px;
}

.tagline-login h4 {
    font-size: 4rem;
    font-family: 'Lexend Deca';
    font-weight: bold;
    color: #00aff0;
}

.navbrand {
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbrand img {
    width: 136px;
}


h3 {
    line-break: strict;
}

.sp-selector-main {
    position: absolute;
    top: 25px;
    right: 45px;
}

@media screen and (max-width: 768px) {
    .sp-selector-main {
        top: 41px;
        right: 8px;
    }

    .app-selector-style {
        padding: 10px 0px;
    }

    .tagline-login {
        top: 29px;
        left: 12px;
    }

    .wrapper-2-cs {
        max-width: 345px;
        margin-top: 30px;
    }
}

@media only screen and (max-width: 1110px) {
    .tagline-login h4 {
        font-size: 2rem;
    }

    .tagline-login h4:lang(ar) {
        font-size: 1.813rem
    }
}