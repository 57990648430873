/* Responsive Layout */

.nav-link {
  color: #0057ad;
}

.row.upper-nav {
  flex-flow: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.nav-brand {
  width: 20% !important;
  padding-left: 0 !important;
}

.nav-brand-ar {
  width: 20% !important;
  padding-right: 0 !important;
}

.right-upper-nav {
  justify-content: flex-end;
  width: 80% !important;
}

.right-upper-nav ul {
  list-style: none;
}

.right-upper-nav .nav-link a {
  text-decoration: none;
  color: #0057ad;
  transition: 0.2s all;
  font-size: 16px;
}

.right-upper-nav .nav-link a:hover {
  color: #3db0f7;
}

.right-upper-nav .btn-action a {
  text-decoration: none;
  color: unset;
}

.right-upper-nav .btn-action {
  justify-content: center;
  align-items: center;
  color: #008cc0;
  border: 2px solid #008cc0;
  background-color: rgb(255, 255, 255);
  transition: 0.3s all;
  font-size: 14px;
  padding: 6px 6px;
  border-radius: 0.375rem;
  font-weight: 600;
  transition: all 0.3s ease 0s;
}

.right-upper-nav .btn-action:hover {
  color: #ffffff;
  border: 2px solid #00aff0;
  background-color: #00aff0;
}

.row.lower-nav {
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 8px 8px 0px 0px;
  border-color: #c6e7fb;
  border-width: 1px 1px 0px;
  border-style: solid;
  overflow: visible;
  flex-wrap: wrap;
}

.sp-logo {
  width: 259px;
  height: 100px;
}

@media (max-width: 575.98px) {
  .sp-logo {
    width: 165px;
  }
}

.langBtn {
  background-image: "none" !important;
}

.langBtn:hover {
  background-color: #00aff0 !important;
}

.lower-left {
  width: 90% !important;
  justify-content: flex-start;
  align-items: center;
  padding-left: 0px;
  font-weight: 400;
  padding-right: 0 !important;
}

.lower-right {
  width: 6% !important;
  padding: 0px;
  min-width: 88px !important;
}

.lower-right ul {
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  list-style: none;
  margin-bottom: 0px;
  padding: 0px;
}

.lower-right .btn-box {
  width: 56px;
  padding: 0px 8px;
  height: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid #c6e7fb;
  cursor: pointer;
  user-select: none;
}

.lower-right {
  height: 44px;
  border-left: 1px solid #c6e7fb;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
}

.lower-right .drp-cs {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.drp-cs:nth-child(1) {
  border-right: 1px solid #e0e0e0;
}

.lower-right .drp-cs a {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #3caff6;
}

.drp-cs strong {
  color: #3caff6;
}

.lower-i {
  background-color: #3caff6;
  color: white;
  border-radius: 30px;
  height: 32px;
  width: 32px;
  line-height: 32px;
}

.lower-right ul li strong {
  color: #41b1f6;
}

.lower-left ul:only-child {
  display: flex;
  margin-bottom: 0px;
  padding: 0px;
  list-style: none;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
}

.lower-left ul li.active {
  border-bottom: 2px solid #0057ad;
  font-weight: 600;
}

.lower-left ul li {
  height: 44px;
  line-height: 44px;
}

.main-nav {
  color: #0057ad;
  height: 44px;
  width: 100%;
  padding: 0px 12px;
  font-size: 14px;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.main-nav:hover {
  color: #3db0f7;
}

ul.sub-menu li {
  height: 44px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

ul.sub-menu li a {
  color: #0057ad;
  display: block;
  padding: 0px 12px;
}

ul.sub-menu li:hover a {
  color: #3db0f7;
}

ul.sub-menu {
  list-style: none;
  padding: 0px;
  position: absolute;
  display: none;
}

.nav-link {
  padding: 0px 12px;
}

.main-nav:hover {
  color: #6ed9fe;
}

span.ant-modal-close-x {
  color: #fff !important;
}

.lower-left ul:only-child {
  display: flex;
  margin-bottom: 0px;
  padding: 0px;
  list-style: none;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
}

.main-head {
  background-color: rgb(240 248 254 / 100%);
  box-shadow: 0 4px 8px 0px rgb(25 85 128 / 10%),
    0 2px 4px -1px rgb(25 85 128 / 20%);
  position: fixed;
  width: 100%;
  font-family: "Lexend Deca", sans-serif;
  z-index: 9;
}

.main-head-index {
  background-color: rgb(240 248 254 / 100%);
  box-shadow: 0 4px 8px 0px rgb(25 85 128 / 10%),
    0 2px 4px -1px rgb(25 85 128 / 20%);
  position: fixed;
  width: 100%;
  font-family: "Lexend Deca", sans-serif;
  z-index: 0;
}

@media only screen and (max-width: 982px) {
  .main-nav {
    justify-content: flex-start;
  }

  .nav-brand {
    width: 64% !important;
  }

  .right-upper-nav {
    width: 20% !important;
  }

  .right-upper-nav ul:first-child {
    display: none !important;
  }

  .right-upper-nav ul:last-child {
    width: 18rem !important;
  }

  .dropdown .border-right {
    border: none;
  }

  .act-btn {
    display: none;
  }

  .right-upper-nav .btn-action {
    padding: 0px;
    height: 40px;
    width: 57px;
  }

  .nav-link {
    padding: 0px 12px;
  }

  .lower-i {
    background-color: #3caff6;
    color: white;
    border-radius: 30px;
    height: 24px;
    width: 24px !important;
    padding: 6px;
    font-size: 16px;
  }

  .quick-acts {
    width: 46rem !important;
  }

  .lower-right {
    width: 20% !important;
    padding: 0px;
  }

  .lower-left ul li {
    display: none;
  }

  .lower-left ul li.active {
    display: block;
  }

  .lower-left {
    width: 74% !important;
    justify-content: flex-start;
    align-items: center;
    padding-left: 0px;
  }

  /* .main-panel {
    width: 291px !important;
  } */

  .side-bar {
    width: 100% !important;
  }

  .menu-sm-cs {
    display: block !important;
  }

  .menu-sm-cs ul li.active {
    display: none !important;
  }
}

.modal {
  z-index: 99999 !important;
}

.w-50-cs {
  width: 48%;
}

.w-50-hide {
  width: unset;
}

.nav_icon {
  font-size: 22px;
}

a.nav-link {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1510px) {
  .lower-left ul li {
    display: none;
  }

  .lower-left ul li.active {
    display: block;
  }

  .lower-left {
    width: 74% !important;
    justify-content: flex-start;
    align-items: center;
    padding-left: 0px;
  }

  .menu-sm-cs {
    display: block !important;
  }

  .menu-sm-cs ul li.active {
    display: none !important;
  }
}
