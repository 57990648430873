.cardGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* grid-template-rows: repeat(auto-fill, 360px); */
  grid-row-gap: 0.5em;
  grid-column-gap: 1em;
}

@media only screen and (max-width: 600px) {
  .cardGrid {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media only screen and (min-width: 601px) and (max-width: 992px) {
  .cardGrid {
    grid-template-columns: repeat(2, 1fr);
  }
}
