.stepperContainer {
  padding: 14px 0px;

  /* margin: 1; */
  display: flex;
  /* align-items: center; */
  justify-content: center;
  align-content: center;
  height: 70vh;
  /* overflow: auto; */
  width: 100% !important;
  /* background-color: red; */
}

.alignEnd {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  align-content: flex-end;
}

.roleCard {
  background: white;
  padding: 10px;
  align-items: center;
  color: #00aff0;
  font-weight: bold;
  justify-content: center;
  border-width: 1px;
  border-color: #00aff0;
  border-radius: 5px;
  margin-left: 5px;
  margin-right: 5px;
}

.roleCard.active {
  background: #00aff0 !important;
  color: white !important;
}

.roleCard:hover {
  background: #00aff0;
  color: white;
}

.pointer {
  cursor: pointer;
}

.onBoarding-container {
  padding: 20px 0px;
  height: 100vh;
  background-color: white;
  border-radius: 20px;
  /* border-top-left-radius: 0px;
  border-top-right-radius: 0px; */
  align-items: center;
  display: block;
  margin: 0% 5%;
  margin-bottom: 50px;
  margin-top: 25px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.onBoarding-Main {
  height: 100vh;
  /* align-items: center; */
  justify-content: center;
  display: flex;
  background-color: #eee;
  flex-direction: column;
}

.mainComponentHeight {
  height: 50vh;
  overflow: hidden;
  width: 100%;
  align-self: center;
  /* overflow-x: auto !important; */
}

@media screen and (max-width: 760px) {
  .overflow-cus-class-y {
    overflow-y: scroll !important;
  }
}

.ant-table-thead .ant-table-cell {
  background-color: #00aff0 !important;
  color: white;
}

.ant-table-thead .ant-table-cell::before {
  border-color: white !important;
}

.ant-table {
  border: 1px solid #00aff0 !important;
  border-radius: 10px;
}

.ant-pagination-item-link {
  color: #00aff0 !important;

  border-color: #00aff0 !important;
  justify-content: center !important;
  display: flex !important;
  align-items: center !important;
}

.ant-pagination-item {
  border-color: #00aff0 !important;
}

.ant-pagination-item a {
  color: #00aff0 !important;
  border-color: #00aff0 !important;
  font-weight: bold !important;
}

.height-fit-content {
  height: fit-content !important;
}

.tab-content {
  padding: 0px;
}
.ant-steps-small .ant-steps-item-title {
  font-size: small !important;
}
.ant-steps-item:last-child {
  display: contents;
}
.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title:after {
  background-color: transparent !important;
}
.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title:after {
  background-color: transparent !important;
}
.ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title:after {
  background-color: transparent !important;
}

.ant-steps-item:last-child {
  display: inline-block !important;
  flex: 0% !important;
}

.instruct-para {
  font-size: 14px;
  color: green;
  margin-top: 3px;
}

.cus-scroll {
  padding-right: 4px;
}

.cus-scroll::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track
.cus-scroll::-webkit-scrollbar-track {
  background: #f1f1f1;
  width: 16px;
} */

.cus-scroll::-webkit-scrollbar-thumb {
  border: 6px solid #75787b;
  box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.03);
  border-radius: 30px;
}

/* .table-style .ant-table-cell {
  width: 150px;
  background-color: red;
} */
