.checkbox {
  font-size: 16px;
  user-select: none;
  min-height: 320px;
  padding: 20px;
  box-sizing: content-box;
}

.checkbox .tree,
.checkbox .tree-node,
.checkbox .tree-node-group {
  list-style: none;
  margin: 0;
  padding: 0;
}

.checkbox .tree-branch-wrapper,
.checkbox .tree-node__leaf {
  outline: none;
}

.checkbox .tree-node {
  cursor: pointer;
}

.checkbox .tree-node .name:hover {
  /* background: rgba(0, 0, 0, 0.1); */
}

.checkbox .tree-node--focused .name {
  /* background: rgba(0, 0, 0, 0.2); */
}

.checkbox .tree-node {
  display: flex;
}

.checkbox .checkbox-icon {
  margin: 0 5px;
  vertical-align: middle;
}

.checkbox button {
  border: none;
  background: transparent;
  cursor: pointer;
}

.checkbox .arrow {
  margin-left: 5px;
  vertical-align: middle;
}

.checkbox .arrow--open {
  transform: rotate(90deg);
}

.ant-select-dropdown {
  /* left: 588px !important; */
}

.textCenter {
  text-align: center;
}

.compSettingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  align-content: center;
  width: 100%;
}

.profileImage {
  height: 350px !important;
  width: 350px !important;
}

.center {
  display: flex;
  align-content: center;
  height: 100%;
  align-items: center;
}

.profileCont {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: flex-end;
}

.ant-radio-button {
  background-color: #3db0f7 !important;
  border-width: 0px !important;
}

.ant-radio-button-wrapper {
  border-color: #3db0f7 !important;
  font-weight: bold;
  text-align: center !important;
  color: #3db0f7 !important;
}

.ant-radio-button-wrapper-checked {
  color: white !important;
}

.filter-fix {
  display: flex !important;
  margin-left: 0px !important;
  align-content: flex-start !important;
  padding-left: 4px !important;
  align-items: center !important;
  appearance: none;
  font-family: "Lexend Deca";
  border-radius: 4px;
  outline: none;
  vertical-align: top;
  display: inline-block;
  border: 2px solid #a1a9b3 !important;
  height: 2.75rem !important;
  min-height: 2.75rem !important;
  margin: 0.5rem 0px !important;
  line-height: normal !important;
  width: 100% !important;
}

.ant-input:focus,
.ant-input-focused {
  box-shadow: none !important;
}

.ant-input {
  padding-left: 16px !important;
}

.role-container {
  background-color: #dddd;
  padding: 10px 20px;
  flex-direction: row;
  display: flex;
  font-weight: bold;

  align-content: center;
  align-items: center;
  margin-top: 10px;
  cursor: pointer;
}

.role-container.active {
  background-color: #3db0f7;
  color: white;
}

.save-btn-container {
  position: absolute;
  /* bottom: 10.5%; */
  /* margin-left: 0.5rem; */
}

@media screen and (max-width: 600px) {
  .save-btn-container {
    /* bottom: 18%; */
  }
}

.role_tree {
  height: 43vh;
  overflow: auto;
}

.line-manager-container {
  display: flex;
  flex-direction: row;
  align-content: space-between;
  align-items: center;
  width: 100%;
  align-content: center;
}
.tab-content {
  padding: 0px !important;
}
