.sp-report-card {
    min-width: 21.875rem;
    min-height: 24vh;
}

@media only screen and (max-width: 768px) {
    .sp-report-card {
        min-width: 100%;

    }
}