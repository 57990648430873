@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&family=Lexend+Deca:wght@300;400&display=swap");

* {
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
  color: unset;
}

body,
html {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  font-family: "Poppins", sans-serif, "Lexend Deca" !important;
}

button {
  font-family: "Lexend Deca";
}

h1 h2 h3 h4 h5 h6 {
  /* font-family: Avenir, -apple-system, BlinkMacSystemFont, "Poppins", "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji",
    sans-serif !important; */
  font-family: "Lexend Deca" !important;
}
label {
  font-family: "Lexend Deca";
}
a {
  text-decoration: none;
  color: unset;
  cursor: pointer;
  font-family: "Poppins", sans-serif, "Lexend Deca" !important;
}

.dropdown-toggle::after {
  display: none;
}
p {
  font-family: "Poppins" !important;
}
.col {
  flex: 1;
  padding: 16px;
}

a:hover {
  text-decoration: none;
  color: unset;
}

.bg-white {
  background-color: white !important;
}

.container_custom {
  margin: 0% 5%;
  padding-bottom: 22px;
}

.shadow-md {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.row {
  margin: 0px;
}

/* Header End */

.top-heading {
  font-size: 2rem;
  font-weight: bold;
  text-transform: capitalize;
  color: black;
  padding: 0px 0px;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  margin-bottom: 0;
  /* margin-top: 21px; */
  gap: 0.4rem;
  line-break: anywhere;
  font-family: "Lexend Deca";
  /* width: 90%; */
}

.sp_main {
  margin: 0% 5%;
  padding-bottom: 22px;
}

.sp-parent-container {
  overflow-x: hidden;
  overflow-y: hidden;
}

h1.top-heading span {
  font-size: 1rem;
  font-weight: 400;
  color: #41464b !important;
}

.ant-modal-body p {
  text-align: left;
}

.nav-tabs .nav-link {
  border: solid transparent;
  border-width: 0 0 2px;
  border-radius: 0;
  line-height: 1;
  font-size: 16px;
  color: #0057ad;
  font-family: "Lexend Deca", sans-serif;
  padding: 13px 8px 10px 8px;
  font-weight: unset;
  text-transform: capitalize;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #0057ad;
  border-color: #0057ad;
  font-weight: bold;
}

.side-bar h3 {
  color: #404040;
  font-size: 24px;
  font-family: "Lexend Deca";
  font-weight: bold;
  padding: 6px;
}

.side-bar {
  border-radius: 8px;
  /* width: 33.33333333%; */
  /* padding: 0px; */
  height: 100%;
  position: relative;
}

.nav-tabs .nav-link.active {
  background-color: transparent;
}

.nav-tabs .nav-link:focus {
  background-color: transparent;
}

.nav-tabs .nav-link:hover {
  background-color: transparent;
}

.nav-link .active:active {
  background-color: #3caff6;
}

.sidebar-head {
  padding: 10px 6px;
  background-color: #eaf6fd;
  /* padding-bottom: 0px; */
  /* min-height: 6rem; */
}

.tab-content {
  padding: 12px 6px;
}

.main-panel {
  /* padding: 0px; */
  border-radius: 0.25rem;
  /* width: 65.6%; */
  height: 100%;
  background-color: #eaf6fd;
}

.main-panel h3 {
  color: #404040;
  font-size: 24px;
  font-family: "Lexend Deca";
  font-weight: bold;
  padding: 6px;
}

.panel-head {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 14px;
}

button.btn.btn-sm-dark {
  background-color: #008cc0;
  color: white;
}

.btn {
  text-transform: none;
}

button.btn.btn-sm-dark span {
  font-size: 14px;
}

.b_b_light {
  border-bottom: 1px solid #dee2e6;
  padding-right: 10px;
}

.b_b_light:nth-child(odd) {
  /* background-color: #E5F6FE; */
}

.circle-calendar {
  height: 70px;
  width: 70px;
  padding: 4px;
  border-radius: 64px;
  box-shadow: 0px 0px 4px 1px #00000054;
}

.col-md-2-calendar.date-active .bottom-part {
  visibility: visible;
}

.col-md-2-calendar .bottom-part {
  -webkit-clip-path: polygon(50% 10%, 30% 100%, 70% 100%);
  clip-path: polygon(50% 10%, 30% 100%, 70% 100%);
  height: 56px;
  background-color: #eaf6fd;
  visibility: hidden;
  margin-top: 12px;
}

.col-md-2-calendar.date-active {
  transform: scale(1.3);
  padding: 0px 8px;
  box-sizing: content-box;
}

.col-md-2-calendar.date-active .circle-calendar {
  cursor: pointer;
}

.date-top {
  color: #00aded;
  font-size: 14px;
  font-family: "Poppins";
}

.calendar-row {
  display: flex;
  background: linear-gradient(
    180deg,
    rgb(60 175 246 / 0%) calc(50% - 1px),
    rgb(162 219 255 / 60%) calc(50%),
    rgba(0, 0, 0, 0) calc(50% + 1px)
  );
  align-items: center;
  justify-content: space-evenly;
  overflow: hidden;
  height: 200px;
  gap: 1.2rem;
}

.cal-summary .progress {
  width: 88px;
  height: 88px;
  background: none;
  margin: 0 auto;
  box-shadow: none;
  position: relative;
}

.col-md-2-calendar {
  width: 14%;
  text-align: -webkit-center;
}

.date-active .date-top {
  font-weight: bold;
  font-size: 12px;
}

.date-active .progress-value {
  font-weight: bold;
}

.summary-row {
  background-color: #eaf6fd;
  padding: 0px 14px 14px 14px;
}

button.btn.btn-lg-light.btn-block.text-start {
  font-size: 16px;
}

.btn-primary-cs {
  color: #ffffff;
  background-color: #00aff0;
  border: 2px solid #00aff0;
}

.btn-primary-cs:hover {
  color: #ffffff;
  background: #008cc0;
  border-color: #008cc0;
}

.btn-primary-outline-cs {
  color: #00aff0;
  border: 2px solid #00aff0;
  background-color: #ffffff;
}

.btn-primary-outline-cs:hover {
  background: #008cc0;
  border-color: #008cc0;
  color: #ffffff;
}

.btn-light-cs {
  background-color: #ebf7ff;
  border: 0;
  box-shadow: none !important;
}

.btn-light-cs span {
  text-decoration: underline;
  color: #0057ad !important;
}

.text-pink {
  color: #008cc0 !important;
}

.text-blue {
  color: #008cc0 !important;
}

.text-pink:hover {
  color: #008cc0 !important;
}

.btn-outline-light {
  border: 1px solid #757575;
}

.underline-cs:hover {
  text-decoration: underline;
}

.fs-16 {
  font-size: 16px;
}

.i-sm {
  font-size: 1.5rem;
}

.i-med {
  font-size: 2rem;
}

.i-large {
  font-size: 4rem;
}

.status .i-large {
  color: #00aff0;
}

.status .col-md-10 {
  color: #0057ad;
  font-family: system-ui;
}

.bg-white {
  background-color: white;
}

.text-white {
  color: white;
}

.summary-row .date-active {
  transform: scale(1.6);
  box-sizing: content-box;
}

.side-bar .progress.blue .progress-bar {
  border-color: #3db0f7;
}

.summary-row .progress.blue .progress-bar {
  border-color: #3db0f7;
}

.summary-row .progress > span {
  width: 51%;
}

.border-custom {
  box-shadow: rgb(214 214 214) 0px 1px 4px;
  border: 1px solid rgb(61, 176, 247);
  border-radius: 8px;
}

.card .card-overlay {
  position: absolute;
  top: 25%;
  left: 16.5%;
}

.form-control {
  box-shadow: none;
  transition: all 0.1s linear;
  border-color: #00aff0 !important;
  box-shadow: inset 0 0 0 1px #00aff0 !important;
}

.card {
  height: 100%;
}

.card-footer-cs {
  width: 100%;
  left: 0px;
  bottom: 0px;
  padding: 14px;
  background-color: #31314ebf;
  color: white;
}

.filter-form {
  appearance: none;
  font-family: "Lexend Deca";
  border-radius: 4px;
  outline: none;
  vertical-align: top;
  display: inline-block;
  border: 2px solid #a1a9b3 !important;
  padding: 0px 16px !important;
  height: 2.75rem !important;
  min-height: 2.75rem !important;
  margin: 0.5rem 0px !important;
  line-height: normal !important;
  width: 100%;
}

.filter-form2 {
  padding: 0px 4px !important;
}

.filter-form-dropdown {
  appearance: none;
  font-family: "Lexend Deca";
  border-radius: 4px;
  outline: none;
  vertical-align: top;
  display: inline-block;
  border: 2px solid #a1a9b3 !important;
  /* padding: 0px 16px !important; */
  height: 2.75rem;
  min-height: 2.75rem !important;
  margin: 0.5rem 0px !important;
  line-height: normal !important;
  width: 100%;
  /* background-color: aquamarine; */
}
.filter_textarea {
  appearance: none;
  font-weight: 600;
  font-family: "Lexend Deca";
  border-radius: 4px;
  outline: none;
  vertical-align: top;
  display: inline-block;
  border: 2px solid #a1a9b3 !important;
  padding: 16px !important;
  min-height: 140px !important;
  margin: 0.5rem 0px !important;
  line-height: normal !important;
  width: 100%;
}

.filter_textarea:focus {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 8px;
  border-color: #00aff0 !important;
}

.filter-form:focus {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 8px;
  border-color: #00aff0 !important;
}

.profile-sm {
  height: 44px;
  width: 44px;
  text-align: center;
  line-height: 44px;
  text-transform: capitalize;
  background-color: #3db0f7;
  border-radius: 50%;
  color: white;
}

.profile {
  height: 70px;
  width: 70px;
  min-width: 70px;
  text-align: center;
  line-height: 70px;
  background-color: #3db0f7;
  border-radius: 50%;
  color: white;
}

.profile-lg {
  text-align: center;
  line-height: 180px;
  background-color: #3db0f7;
  border-radius: 50%;
  color: white;
  font-size: 28px;
  width: 180px;
  height: 180px;
  border: 3px solid white;
  box-shadow: rgb(207 216 220) 0px 3px 6px;
}

.profile-top {
  display: flex;
}

.profile-top h4 {
  font-weight: bold;
  font-size: 18px;
  text-transform: capitalize;
}

.dropdown_pos_fix {
  border: 1px solid #c5e7fb;
}

.modal-cs h4 {
  font-size: 20px;
  font-family: "Lexend Deca", sans-serif;
}

.bg-sec-cs {
  background-color: #3db0f7;
}

.ant-modal-header {
  background-color: #3db0f7 !important;
}

.ant-modal-title {
  color: #ffffff !important;
}

.accordion-body .table {
  font-family: galano, Arial, Helvetica, sans-serif;
  color: #404040;
  font-size: 16px;
}

.info-i {
  height: 26px;
  width: 26px !important;
  border: 2px solid #0057ad;
  text-align: center;
  line-height: 23px;
  border-radius: 50px;
  margin: 0px 0px 0px 8px;
  color: #0057ad;
  font-size: 14px;
  padding: 4px;
  vertical-align: bottom;
}

.info-i-sm {
  height: 22px;
  width: 22px !important;
  border: 2px solid #0057ad;
  text-align: center;
  line-height: 18px;
  border-radius: 50px;
  margin: 0px 0px 0px 8px;
  color: #0057ad;
  font-size: 11px;
  padding: 4px;
  vertical-align: bottom;
}

.info-i-light {
  height: 26px;
  width: 26px !important;
  border: 2px solid #ffffff;
  text-align: center;
  line-height: 23px;
  border-radius: 50px;
  margin: 0px 4px 0px 0px;
  color: #ffffff;
  font-size: 14px;
  padding: 4px;
  vertical-align: bottom;
}

button.accordion-button {
  border: none;
  padding: 12px;
}

.filter-form-sm {
  -webkit-appearance: none;
  appearance: none;
  font-family: galano-bold, galano, sans-serif;
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  outline: none;
  vertical-align: middle;
  display: inline-block;
  border: 2px solid rgb(161, 169, 179) !important;
  padding: 0px 16px !important;
  height: 2.75rem !important;
  min-height: 2.75rem !important;
  line-height: normal !important;
  width: 50% !important;
}

.filter-form-md {
  -webkit-appearance: none;
  appearance: none;
  font-family: galano-bold, galano, sans-serif;
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  outline: none;
  vertical-align: middle;
  display: inline-block;
  border: 2px solid rgb(161, 169, 179) !important;
  padding: 0px 16px !important;
  height: 2.75rem !important;
  min-height: 2.75rem !important;
  line-height: normal !important;
}

.filter-form-t-area {
  -webkit-appearance: none;
  appearance: none;
  font-family: galano-bold, galano, sans-serif;
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  outline: none;
  vertical-align: middle;
  display: inline-block;
  border: 2px solid rgb(161, 169, 179) !important;
  padding: 0px 16px !important;
  width: 62%;
}

.lower-right ul li {
  width: 100% !important;
}

.lower-right ul li a {
  width: 100% !important;
}

.lower-right ul li.notification-box {
  height: 128px;
  display: flex;
  align-items: center;
}

.lower-right ul li.notification-box p {
  width: 100%;
  text-align: center;
}

.text-black-50 {
  color: #757575 !important;
}

.bg-black-20 {
  background-color: #eaecf1;
}

span.col-dot {
  height: 14px;
  width: 14px;
  display: inline-block;
  margin: 0px 4px 0px 0px;
  border-radius: 4px;
}

.offcanvas a:hover {
  text-decoration: underline;
}

.dropdown-item.active,
.dropdown-item:active {
  color: initial;
  text-decoration: none;
  background-color: initial;
}

.quick-acts {
  width: 32rem !important;
}

.quick_act_btn {
  color: #0057ad !important;
}

.quick_act_btn:hover,
.quick_act_btn:focus {
  color: #1784ff;
}

.text-primary {
  color: #0057ad !important;
}

.text-info {
  color: #3db0f7 !important;
}

.btn-action a:hover {
  color: white !important;
}

.border-right {
  border-right: 1px solid #e0e0e09e;
}

.border-top-pink {
  border-top: 2px solid #00aff0;
}

.border-pink {
  border: 2px solid #00aff0;
}

.btn-disabled-cs:hover {
  cursor: not-allowed;
  background-color: rgb(245, 247, 250) !important;
  border: 2px solid rgb(161, 169, 179) !important;
  color: grey !important;
  box-shadow: none;
}

.btn-disabled-cs {
  background-color: rgb(245, 247, 250) !important;
  border: 2px solid rgb(161, 169, 179) !important;
  color: grey !important;
  box-shadow: none;
  pointer-events: none;
}

.dropdown-menu.show:active,
.dropdown-menu.show:focus-within {
  display: block;
}

.new-notf {
  width: 30rem !important;
  height: 686px;
  overflow-x: scroll;
}

a {
  color: inherit;
}

.sec-cs-primary {
  border: 2px solid #3db0f7;
  background-color: #ebf7ff;
  border-radius: 8px;
}

.sec-cs-border {
  border: 2px solid #3db0f7;
}

.w-50-p {
  width: 48% !important;
}

.w-25-cs {
  width: 25% !important;
}

.w-24 {
  width: 24%;
}

.w-75-cs {
  width: 75% !important;
}

.w-25 {
  width: 25% !important;
}

.w-75-cs {
  width: 75% !important;
}

.sec-cs-card {
  background-color: #fafdff;
}

.bg-light-cs {
  background-color: #f1f8fe;
}

.b-r-lgt {
  border-right: 1px solid #d8e3eb;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

fw-bold {
  font-weight: 900;
}

.small {
  font-size: 14px;
}

.rota-crd.active {
  background-color: #3caff6;
  color: white;
}

.right-0 {
  right: 0;
}

.circle-check {
  height: 18px;
  width: 18px;
}

hr.pr-hr {
  background-color: #3db0f7;
  opacity: 0.9;
}

.round {
  position: relative;
}

.round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 28px;
  left: 0;
  position: absolute;
  top: 0;
  width: 28px;
}

.round label:after {
  border: 2px solid #3caff6;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 7px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 12px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
  background-color: #ffffff;
  border-color: #3db0f7;
}

.round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

.right-14 {
  right: 14px;
}

.form-check-input:checked {
  background-color: #00aff0;
  border-color: #e0057a;
}

.form-check-input:focus {
  border-color: #e0057a;
  outline: 0;
  box-shadow: none;
}

.form-check-input[type="radio"]:checked:after {
  border-color: #00aff0;
  background-color: #00aff0 !important;
}

.form-check-input:checked:focus {
  border-color: #00aff0;
}

.rdo-sec {
  display: inline-flex;
  justify-content: space-evenly;
  border-radius: 5px;
  min-width: 125px !important;
}

.rdo-sec .option {
  background: #fff;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 5px;
  cursor: pointer;
  padding: 6px 12px;
  border: 2px solid #3db0f7;
  transition: all 0.3s ease;
  min-width: 125px !important;
}

.rdo-sec .option .dot {
  height: 20px;
  width: 20px;
  background: #ffffff;
  border-radius: 50%;
  position: relative;
  border: 2px solid #3db0f7;
}

.rdo-sec .option .dot::before {
  position: absolute;
  content: "";
  top: 2px;
  left: 2px;
  width: 12px;
  height: 12px;
  background: #3db0f7;
  border-radius: 50%;
  opacity: 0;
  transform: scale(1.5);
  transition: all 0.3s ease;
}

.rdo-sec input[type="radio"] {
  display: none;
}

.rdo-sec .optn-1:checked:checked ~ .option-1,
.rdo-sec .optn-2:checked:checked ~ .option-2 {
  border-color: #3db0f7;
  background: #d0ebfd;
}

.rdo-sec .optn-1:checked:checked ~ .option-1 .dot,
.rdo-sec .optn-2:checked:checked ~ .option-2 .dot {
  background: #fff;
  border: 2px solid #42b2f7;
}

.rdo-sec .optn-1:checked:checked ~ .option-1 .dot::before,
.rdo-sec .optn-2:checked:checked ~ .option-2 .dot::before {
  opacity: 1;
  transform: scale(1);
}

.rdo-sec .option span {
  color: #424242 8 !important;
  font-size: 16px;
  padding: 2px 6px;
}

.rdo-sec .optn-1:checked:checked ~ .option-1 span,
.rdo-sec .optn-2:checked:checked ~ .option-2 span {
  color: #424242;
}

@media screen and (max-width: 768px) {
  .container_custom {
    margin: 0% 5%;
  }

  .sp_main {
    margin: 0% 5%;
  }

  .rdo-sec {
    display: grid;
  }

  /* .smallCard {
    min-height: 40vh;
    min-width: 22rem;
    width: calc(99% / 3);
    width: 33%;
  } */
}

.bordered {
  border: 1px solid #d7d4d6;
}

ul {
  list-style: none;
}

/* span {
    color: black !important;
} */
button a {
  color: unset !important;
}

.rota-crd h6 {
  font-size: 1.4rem;
}

.rota-crd.active h6 {
  color: #fff;
  font-size: 1.4rem;
}

.bullet-cs ul li::before {
  content: "\2022";
  color: #3db0f7;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
  font-size: 26px;
  text-align: center;
  vertical-align: middle;
}

.tbl-row-cs {
  background-color: #e4f3ff;
  vertical-align: middle;
  border-width: 0.1px;
}

.tbl-row-cs:nth-child(odd) {
  background-color: white;
}

.border-bottom-light {
  border-bottom: 1px solid #d8e3eb !important;
}

.translate-25 {
  transform: translate(25%, 25%) !important;
}

.table .accordion-button:not(.collapsed) {
  color: #0c63e4;
  background-color: #ffffff;
  box-shadow: none;
  outline: none !important;
}

.w-50-cs {
  width: 48%;
}

.shadow-md {
  box-shadow: 0 4px 8px 0px rgba(25, 85, 128, 0.1),
    0 2px 4px -1px rgba(25, 85, 128, 0.2);
}

.border-cs-grey {
  border: 1px solid #ced3d9;
}

.h-48 {
  height: 48% !important;
}

.mb-2-per {
  margin-bottom: 2%;
}

.input-group > .btn {
  padding-top: 0%;
}

.w-250-px {
  width: 250px !important;
}

.lightning-gradient {
  background: radial-gradient(
      136.08% 67.37% at 4.09%,
      at 0%,
      #eac5de 0%,
      rgba(244, 225, 238, 0) 100%
    ),
    radial-gradient(
      94.07% 94.07% at 18.21%,
      at 109.82%,
      #acc7fd 0%,
      rgba(186, 209, 252, 0) 100%
    ),
    #f3f5f5;
  background: radial-gradient(
      136.08% 67.37% at 4.09% 0%,
      #eac5de 0%,
      rgba(244, 225, 238, 0) 100%
    ),
    radial-gradient(
      94.07% 94.07% at 18.21% 109.82%,
      #acc7fd 0%,
      rgba(186, 209, 252, 0) 100%
    ),
    #f3f5f5;
}

.h-img-card-bg {
  height: 100%;
}

.card .card-header-cs {
  height: 260px;
}

.card .card-header-cs img {
  height: 100%;
}

.w-84 {
  width: 84%;
}

.w-10 {
  width: 10%;
}

.form-control-lgt {
  border-color: #92a5b3 !important;
  box-shadow: inset 0 0 0 1px #92a5b3 !important;
}

.cards-access .card {
  height: 564px;
  overflow: hidden;
}

.cards-access .card-sm {
  height: 264px !important;
}

.h-card {
  height: 564px;
}

.accordion-item {
  background-color: transparent;
  border-color: transparent;
}

.accordion-button:not(.collapsed) {
  background-color: transparent;
  box-shadow: none;
}

.accordion-button:focus {
  z-index: 3;
  border-color: transparent;
  outline: 0;
  box-shadow: 0 0 0 0.25rem transparent;
}

.accord-nav i {
  border: 2px solid #008cc0;
  height: 28px;
  width: 28px;
  text-align: center;
  border-radius: 50px;
  line-height: 24px;
  color: #008cc0;
}

.w-25-me {
  width: 280px;
}

.team-cards .team-crd {
  min-width: 350px !important;
  /* cursor: pointer; */
  /* width: auto; */
  transition: 0.5s;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 60px !important;
  border-top-left-radius: 60px !important;
  border-bottom-left-radius: 0px;
}

.form-check-input:focus:before {
  opacity: 0;
  box-shadow: none;
  transform: none;
  transition: none;
}

.form-check-input {
  border: 2px solid rgba(0, 0, 0, 0.5);
}

.team-cards .team-crd:hover {
  transform: translateY(-20px);
  box-shadow: 0px 4px 13px 0px rgba(79, 212, 251, 0.5);
}

select {
  background-repeat: no-repeat;
  background-position: right 5px center;
  background-image: url(./assets/images/select.png);
  background-size: 10px 24px;
}

.date-range-cs {
  background-repeat: no-repeat;
  background-position: right 5px center;
  background-image: url(./assets/images/calendar.png);
}

.setStatus {
  border: 1px solid #a2dbff;
  padding: 8px 10px;
  color: #333333;
  background-color: #d0ebfd;
  font-size: 15px;
  font-family: "Poppins";
  cursor: pointer;
}

.setStatus.active {
  border: 1px solid #a2dbff;
  padding: 8px 10px;
  color: white;
  background-color: #3db0f7;
  font-size: 15px;
  font-family: "Poppins";
}

.w_setstatus {
  width: 450px;
  height: 100%;
}

.setStatus_typo {
  font-size: 16px;
  font-family: "poppins";
}

.reportCard {
  border: solid 0.5px #dddd;
  border-radius: 5px;
}

.bg-lightblue-cs {
  background-color: #d0ebfd;
}

.filter_btns .btn {
  display: inline-block;
  position: relative;
  background: rgb(255, 255, 255);
  padding: 8px 16px;
  margin: 4px 0px 0px 8px;
  color: rgb(61, 176, 247);
  font-weight: 700;
  border: 2px solid rgb(208, 235, 253);
  border-radius: 8rem;
  text-align: center;
}

.filter_btns .btn:hover {
  background: rgb(208, 235, 253);
}

.edit_profile {
  height: 40px;
  width: 40px !important;
  text-align: center;
  font-size: 8px;
  padding: 12px;
  border-radius: 50px;
}

.profile-details-section {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.profile_name_email {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}

.main-profile-picture {
  position: relative;
  overflow: hidden;
}

.main-profile-picture-image {
  position: absolute;
}

.upload-profile-picture {
  position: absolute;
  display: none;
  transition: 0.8s all;
  height: 100%;
  width: 100%;
}

.main-profile-picture:hover .upload-profile-picture {
  display: block;
  background-color: #0000003b;
}

.empty-box {
  box-shadow: none;
  background: transparent;
}

@media only screen and (max-width: 1110px) {
  .left-start-col {
    min-width: 40px;
    padding: 6px;
  }

  .empty-box {
    display: none !important;
  }

  .profile-details-section {
    display: flex;
    flex-flow: column;
    align-items: center;
    gap: 1rem;
  }

  .profile_name_email {
    grid-template-columns: repeat(1, 1fr);
  }

  .working-pattern-row-cs {
    grid-template-columns: repeat(1, 1fr) !important;
  }

  .working-pattern-col-cs {
    min-height: 3rem;
    padding: 0rem 1rem !important;
  }

  .working-pattern-col-actions {
    justify-content: flex-start !important;
  }

  .hide-on-mobile {
    display: none !important;
  }

  .working-pattern-col-cs:first-child {
    background-color: #f1f9fe;
  }

  .wtp-items {
    display: flex;
    flex-flow: column;
    gap: 4rem;
  }

  .btn-block-sm {
    display: block;
  }

  .table-cstm th,
  .table-cstm td {
    min-width: 280px;
  }

  .profile-top {
    justify-content: center;
  }

  .profile-top .profile-lg {
    height: 8rem;
    width: 8rem;
    line-height: 8rem;
  }

  .table-cstm tr {
    display: flex;
    justify-content: space-between;
  }

  .col-md-2-calendar {
    display: none;
  }

  .col-sm-calendar {
    display: block;
    width: 100% !important;
  }

  .calendar-row.bg-white {
    padding: 0px 0px;
  }

  .col-sm-calendar.date-active-2 {
    width: 100% !important;
  }

  .d-none-sm {
    display: none;
  }

  .w-50-hide {
    width: unset !important;
  }

  .h-48 {
    height: 260px !important;
  }

  .cards-access .card-sm {
    margin-bottom: 2%;
  }

  .b-r-lgt {
    border-bottom: 1px solid #d8e3eb;
  }

  .w-100-sm {
    width: 100% !important;
  }

  .hide-sm {
    display: none;
  }

  .center-sm {
    justify-content: center !important;
    text-align: center;
  }

  .left-sm {
    justify-content: start !important;
    text-align: left;
  }

  .ant-steps-item-title {
    display: none;
  }

  .ant-steps.ant-steps-vertical {
    display: flex;
    flex-flow: row;
    align-items: center;
  }

  .flex-wrap-sm {
    flex-wrap: wrap;
  }

  .flex-wrap-sm-rev {
    flex-wrap: wrap-reverse;
  }

  .w-100-sm {
    width: 100% !important;
  }

  .team-cards .team-crd {
    min-width: 350px !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 40px !important;
  }

  .team-cards .card .small {
    position: absolute;
  }

  .sc-bdvvtL:after {
    visibility: hidden;
  }

  .rbc-toolbar {
    justify-content: flex-start;
  }

  .rbc-toolbar .rbc-toolbar-label {
    text-align: left;
  }

  .MuiTabs-flexContainer {
    flex-wrap: wrap;
  }

  .shiftContainer {
    min-width: 728px;
  }

  .rbc-row-content-scroll-container {
    overflow-y: scroll;
  }
}

/* Switch */
.team-cards .profile {
  margin-right: 8px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ddd;
  transition: 0.4s;
  box-shadow: rgb(0 0 0 / 25%) 0px 2px 5px inset;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  box-shadow: rgb(0 0 0 / 25%) 0px 2px 8px;
}

input:checked + .slider {
  background: #00aff3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #00aff3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.emtypeCard {
  height: 100px;
  border: 1px solid rgb(161, 169, 179);
  background: rgb(255, 255, 255);
  border-radius: 4px;
  padding: 15px;
  cursor: pointer;
}

.emtypeCard.active {
  border: 1px solid rgb(162, 219, 255);
  background: rgb(208, 235, 253);
  box-shadow: rgb(136 136 136) 1px 4px 8px -4px;
}

.summaryCard {
  width: 100%;
  justify-content: space-between;
  display: flex;
  border: #dddd 1px solid;
  padding: 8px 12px;
}

.summaryCard p:last-child {
  text-align: end;
}

.modal-backdrop.show {
  display: none;
}

.spinnerContainer {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999999;
  height: 100%;
  width: 100%;
  background-color: rgb(0, 0, 0, 0.5);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.overTimeCard {
  padding: 10px;
  width: 100%;
  /* background: yellow; */
  /* height: 200px; */
  display: flex;
  border-bottom: grey;
  border-width: 1px 0px;
}

.overTimeCard:nth-child(odd) {
  background-color: #e5f6fe;
}

.iconView {
  height: 100%;
  width: 5%;
  justify-content: center;
  display: flex;
  /* background-color: red; */
}

.bodyView {
  /* background-color: purple; */
  padding: 5px;
  height: 100%;
  width: 80%;
}

.actionButtonView {
  /* background-color: orange; */
  height: 100%;
  width: 15%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.statusButtonSpan {
  font-size: 14px;
}

.bodyHeadingView {
  display: flex;
  align-items: center;
}

.statusButtonSpan {
  border-radius: 15px;
  margin-left: 5px;
  padding: 5px 10px;
}

.bodyHeading {
  font-weight: bold;
}

.timeView {
  margin-top: 5px;
}

.timeText {
  font-size: 14px;
}

.userNameView {
  margin-top: 5px;
}

.userNameViewText {
  font-size: 14px;
}

.overTimeCardStatusPanel {
  margin-top: 15px;
  display: flex;
  width: 100%;
}

.panelCard {
  width: 50%;
}

.overViewICon {
  font-size: 20px;
  cursor: pointer;
}

.greenTag {
  background-color: #51d086;
}

.blueTag {
  background-color: #334fab;
  color: white;
}

.heading {
  font-weight: bold;
}

.decisionCard {
  height: 50px;
  width: 50px;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-width: 0.4px;
  border-color: #dddd;

  /* -webkit-box-shadow: -5px 4px 7px 8px rgba(0,0,0,0.23);  */
  /* box-shadow: -5px 4px 7px 8px rgba(0,0,0,0.23); */
}

.decisionCard.active {
  background-color: #3db0f7;
  color: white;
  font-weight: bold;
}

.formLabel {
  width: 100%;
}

.formRow {
  justify-content: space-between;
}

.logOverTimeSelectCard {
  height: 150px;
  background-color: #edf7ff;
  /* padding: 20px; */
  border: 2px #3db0f7 solid;
  border-radius: 10px;
  margin-bottom: 20px;
  width: 50%;
}

@media only screen and (max-width: 600px) {
  .logOverTimeSelectCard {
    width: 100%;
  }
}

.logOverTimeSelectCardFooter {
  background-color: #d0e9fd;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 5px;
}

.shiftContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: white;
  border-radius: 5px;
}

.shiftContainer:nth-child(odd) {
  background-color: #eeee;
}

.avatarDiv {
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0057ad;
  border-radius: 50px;
  color: white;
}

.avatarDetail .text-primary {
  cursor: pointer;
}

.avatarDetail {
  margin-left: 10px;
  margin-top: 2px;
}

h5.card-title {
  color: black;
  font-size: 1.3rem;
}

.requestHeader {
  display: flex;
  background-color: #ced3d9;
  color: #404040;
  padding: 10px;
  flex-direction: row;
  font-size: 16px;
  font-family: galano-bold, galano, sans-serif;
  font-weight: bold;
}

a.summary_btns {
  width: 48%;
  border-radius: 4px;
  padding: 10px 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px; */
  transition: 0.4s all ease-in;
  background-color: #fff;
  font-size: 1rem;
  font-family: "Lexend Deca";
}

.summary_btns:hover,
.summary_btns:focus {
  color: #1784ff;
}

a.summary_btns.annual {
  color: rgb(112, 196, 249);
  border: 2px solid rgb(112, 196, 249);
}

a.summary_btns.sick {
  color: rgb(224, 174, 46);
  border: 2px solid rgb(224, 174, 46);
}

a.summary_btns.other {
  color: rgb(125, 175, 163);
  border: 2px solid rgb(125, 175, 163);
}

a.summary_btns.timeoff {
  color: rgb(255, 157, 120);
  border: 2px solid rgb(255, 157, 120);
}

.absence_btn {
  width: 48%;
  border-radius: 4px;
  padding: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px; */
  transition: 0.4s all ease-in;
  background-color: #fff;
  font-size: 1rem;
  font-family: "Lexend Deca";
}

.absence_btn:hover,
.absence_btn:focus {
  color: #1784ff;
}

.absence_btn.lateness {
  color: rgb(243, 202, 64);
  border: 2px solid rgb(243, 202, 64);
}

.absence_btn.sickness {
  color: rgb(182, 227, 136);
  border: 2px solid rgb(182, 227, 136);
}

.text_sm_cs {
  font-size: 14px;
  font-family: "Lexend Deca";
}

.simple_text_2 {
  font-size: 16px;
  font-family: "Lexend Deca";
}

.left_summary_progress {
  width: 200px;
  margin-top: -7%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.left_summary_progress .circle-calendar {
  width: 66%;
}

.left_summary_progress .box-cs-1 {
  transform: scale(1.8);
}

.overtime_subhead {
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-family: "Poppins";
  display: flex;
}

.shiftStart {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.sidebar_alert_bottom {
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 400;
}

.bg-sec-cs h5 {
  color: white;
  font-size: 16px;
  font-family: "Lexend Deca";
}

.disabled {
  opacity: 0.6 !important;
  cursor: not-allowed !important;
  background-color: rgb(161, 169, 179) !important;
  border-color: rgb(161, 169, 179) !important;
  color: rgb(255, 255, 255) !important;
  pointer-events: none !important;
}

.profile-lg span {
  text-transform: uppercase;
}

.capitialize {
  text-transform: capitalize;
}

.flex_vert_align {
  display: flex;
  align-items: center;
}

.quick_act_btn {
  color: #0057ad !important;
  gap: 4px;
  font-size: 14px;
}

.quick_act_btn:hover {
  color: #1784ff !important;
}

.quick-acts {
  width: 35rem !important;
}

.status_bg {
  background-color: #ccebff;
}

.text_light_2 {
  color: #404040;
}

.card_bg_text {
  font-size: 14px;
  color: white;
  font-family: "Poppins";
  padding: 0% 14px;
}

.card_logo img {
  width: 200px;
}

.subheading_2 {
  font-size: 21px;
}

.text_link {
  cursor: pointer;
  color: #0057ad;
}

.text_link:hover,
.text_link:focus {
  color: #1784ff;
}

.text_warning {
  color: #ff5000;
}

.status {
  display: flex;
  gap: 5px;
  padding: 0px 6px;
  justify-content: flex-start;
  color: #0057ad;
}

.status h2 {
  font-weight: bold;
  font-size: 32px;
  color: #0057ad;
}

.css-k21jxg-MuiButtonBase-root-MuiTab-root {
  font-size: 0.9rem !important;
  padding: 0px 36px !important;
  font-family: "Lexend Deca" !important;
  color: #0057ad !important;
}

.css-k21jxg-MuiButtonBase-root-MuiTab-root:hover {
  color: #0070e0 !important;
}

.css-k21jxg-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #0070e0 !important;
  border-bottom: 3px solid #0057ad !important;
  font-weight: 700 !important;
}

.profile_absences {
  font-family: "Lexend Deca";
}

.profile_absences h4 {
  font-size: 24px;
}

.all_absences {
  padding: 0px;
  font-size: 24px;
  font-family: "Lexend Deca";
  font-weight: bold;
  text-align: center;
  margin: 16px;
}

.p_font {
  font-family: "Lexend Deca";
}

.accordion-header button h6 {
  font-weight: 700;
  font-family: "Lexend Deca";
}

.accordion-header .bg_acc {
  background-color: #f5f7fa !important;
  padding: 12px 12px !important;
}

.emp_head {
  font-size: 24px;
  font-family: "Lexend Deca";
  font-weight: bold !important;
}

.card-header {
  padding: 0.75rem 1rem;
  font-size: 1rem;
  margin-bottom: 0;
  background-color: #ebf7ff !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  font-family: "Poppins";
}

.profile_head_2 {
  font-size: 1.2rem;
}

.toil {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}

.number_lg {
  font-size: 24px;
}

.text_leave-absence {
  color: #484cb0;
}

.text_leave-holidays {
  color: #33425b;
}

.text_leave-toil {
  color: #526ed0;
}

.text_leave-other {
  color: #5baaec;
}

.modal.fade.show {
  background-color: #00000099;
}

.css-k008qs button,
.css-heg063-MuiTabs-flexContainer button {
  font-family: "Poppins";
  font-size: 14px;
  color: #000000ba !important;
}

.css-k008qs button.Mui-selected,
.css-heg063-MuiTabs-flexContainer button.Mui-selected {
  color: rgb(24, 144, 255) !important;
  font-weight: 500;
}

.box-cs-1 {
  height: 75px;
  width: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #a2dbff;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
  border-radius: 1rem;
  overflow: hidden;
  border: 2px solid #ffffff;
  color: #3caff6;
}

.box-cs-1 .fill {
  font-size: 2rem;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 75%;
  width: 75%;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
  z-index: 1;
}

.col-md-2-calendar.date-active .box-cs-1 {
  background-color: #00aff0;
  color: #00aff0;
}

.badge {
  font-weight: 400;
  background-color: #008cc0;
}

.badge2 {
  width: 20px;
  color: #0057ad;
  margin-left: 10px;
  display: flex;
  text-align: center;
  /* padding-top: 5px; */
  /* padding-bottom: 5px; */
}

.ant-space {
  display: inline-flex;
  width: 100%;
  padding: 2%;
}

.steps-content {
  min-height: 200px;
  margin-top: 16px;
  margin-bottom: 16px;
  padding: 8px;
  text-align: center;
  background-color: #fafafa;
  border: 1px dashed #e9e9e9;
  border-radius: 2px;
}

.form-required-cs,
.form-required-cs:hover,
.form-required-cs:active,
.form-required-cs:focus {
  border-color: #ff5000 !important;
  color: #ff5000;
  width: 100%;
}

.form-group-cs {
  display: flex;
  flex-flow: column;
}

.collapsed {
  background-color: transparent;
}

.accordion-button:not(.collapsed) {
  color: #262626;
}

.ant-steps-item-icon {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border-color: #ffffff00 !important;
  box-shadow: 0 0 0 2px rgb(255 255 255 / 0%) !important;
  border-right-width: 0px;
  outline: 0;
}

.ant-select.filter-form.w-75.ant-select-single.ant-select-show-arrow.ant-select-show-search {
  padding: 0px !important;
}

.ant-select-selector {
  height: 100% !important;
  padding: 0px !important;
}

.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  padding: 0;
  line-height: 36px !important;
}

.ant-steps-item-container {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.ant-steps-vertical {
  display: flex;
  flex-direction: column;
  align-items: center;
}

[data-theme="compact"]
  .site-collapse-custom-collapse
  .site-collapse-custom-panel,
.site-collapse-custom-collapse .site-collapse-custom-panel {
  margin-bottom: 24px;
  overflow: hidden;
  background: #f7f7f7;
  border: 0px;
  border-radius: 2px;
}

.ant-steps.ant-steps-horizontal.ant-steps-label-horizontal {
  width: 100% !important;
  display: flex;
  justify-content: space-between;
}

.ant-steps-item {
  width: 33%;
}

.ant-modal-content {
  max-height: 80vh;
  overflow: auto;
}

.day-select {
  display: flex;
  gap: 6px;
}

.day-select span {
  border: 2px solid #a1a9b3;
  display: flex;
  align-items: center;
  width: 36px;
  height: 36px;
  justify-content: center;

  border-radius: 2px;
  font-weight: 600;
  color: #a1a9b3;
  cursor: pointer;
}

.day-select span.active {
  color: white;
  border-color: #00000024;
  background-color: #3caff6;
}

.day-select span:hover {
  color: white;
  border-color: #00000024;
  background-color: #3caff6;
}

.holiday-card {
  width: 300px;
  min-height: 240px;
  border: 1px solid rgb(161, 169, 179);
  background-color: rgb(255, 255, 255);
}

.holiday-card.active {
  border: 1px solid rgb(162, 219, 255);
  background: rgb(208, 235, 253);
  box-shadow: rgb(136 136 136) 1px 4px 8px -4px;
}

/* .scroll-container {
  max-height: 500px;
  overflow: scroll;
} */

.rbc-row-content-scroll-container {
  overflow-y: scroll;
  padding-bottom: 50px;
  /* Hide scrollbar for Chrome, Safari and Opera */
  /* -ms-overflow-style: none; */
  /* IE and Edge */
  /* scrollbar-width: none; */
  /* Firefox */
  /* max-height: 600px; */
}

.rbc-row-content-scroll-container::-webkit-scrollbar {
  display: none;
}

.filter-form.req-err {
  border-color: #dc3545 !important;
}

.dropdown-item {
  cursor: pointer;
}

.nav-tabs {
  border-bottom: 1px solid #e0e0e0;
}

.clocked_list {
  /* max-height: 54vh;
  overflow-y: scroll; */
}

span.MuiTabs-indicator {
  display: none;
}

.myFlex-cs {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  width: 100%;
}

.sp-searchitems {
  width: 100%;
}

.terms {
  width: 100%;
}

.not-found-heading {
  font-family: galano-bold, galano, sans-serif;
  font-size: 140px;
  margin: 0px;
  text-align: center;
  font-weight: bold;
  color: rgb(162, 219, 255) !important;
}

.not-found-desc {
  font-family: galano-bold, galano, sans-serif;
  font-weight: bold;
  font-size: 1.5rem;
  text-align: center;
  margin-top: 2rem;
}

.name-initials {
  font-size: xxx-large;
  font-weight: bold;
}

.working-time-patterns {
  background-color: #e4f3ff;
  border-radius: 0px 0px 0px 0px !important;
}

.working-pattern-row-cs {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  text-transform: capitalize;
}

.working-pattern-col-cs:nth-child(3),
.working-pattern-col-cs:nth-child(4) {
  justify-content: center;
}

.working-pattern-col-actions {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.working-pattern-col-actions:first-child span {
  min-width: 1rem;
}

.working-pattern-col-cs {
  display: flex;
  justify-content: left;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
  gap: 4px;
}

.bg-primary {
  background-color: #f1f9fe !important;
}

.ant-breadcrumb-link {
  cursor: pointer;
}

.ant-breadcrumb span:last-child {
  color: #0057ad;
}

.PEvci {
  display: block;
  margin-top: 6vh;
}

.h-100-vs-cs {
  height: 100vh;
}

.nice-dates-popover {
  box-shadow: none;
  border: 1px solid #ddd;
  border-radius: 2px;
  max-width: 480px;
  /* transition: none; */
  /* max-height: 300px; */
}

.view-staffing-table {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
}

.staffingCheckBox {
  cursor: pointer;
  max-width: 300px;
  max-height: 300px;
}

.staffingCheckBox img {
  height: 80px;
  width: 80px;
}

.staffingCheckBox .active h6 {
  font-size: 1rem;
  font-weight: bold;
}

.staffingCheckBox h6 {
  font-size: 1rem;
  font-weight: 800;
}

.staffingCheckBox .active p {
  font-size: 0.8rem;
  font-weight: 500;
}

.staffingCheckBox p {
  font-size: 0.8rem;
  font-weight: 500;
}

.staffing-btn-cs-width {
  width: 100%;
}

.flex-end-column {
  display: flex;
  flex-flow: column;
  align-items: end;
}

.kakao {
  width: 500px;
  height: 100px;
  /* background:url(./kakao.png) no-repeat;  */
  /*background-size:contain;*/
}

.pdf-page {
  margin: 0 auto;
  box-sizing: border-box;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.3);
  color: #333;
  position: relative;

  /* background image add */
  background: #fff url(http://www.arabianhsr.com/en/images/footer.gif) bottom
    center no-repeat;
  background-size: contain;

  /* background-color: #fff; */
}

.pdf-header {
  position: absolute;
  top: 0.5in;
  height: 0.6in;
  left: 0.5in;
  right: 0.5in;
  border-bottom: 1px solid #e5e5e5;
}

.invoice-number {
  padding-top: 0.17in;
  float: right;
}

.pdf-footer {
  position: absolute;
  bottom: 0.5in;
  height: 0.6in;
  left: 0.5in;
  right: 0.5in;
  padding-top: 10px;
  border-top: 1px solid #e5e5e5;
  text-align: left;
  color: #787878;
  font-size: 12px;
}

.pdf-body {
  position: absolute;
  top: 3.7in;
  bottom: 1.2in;
  left: 0.5in;
  right: 0.5in;
}

.size-a4 {
  width: 8.3in;
  height: 11.7in;
}

.size-letter {
  width: 8.5in;
  height: 11in;
}

.size-executive {
  width: 7.25in;
  height: 10.5in;
}

.company-logo {
  font-size: 30px;
  font-weight: bold;
  color: #3aabf0;
}

.for {
  position: absolute;
  top: 1.5in;
  left: 0.5in;
  width: 2.5in;
}

.from {
  position: absolute;
  top: 1.5in;
  right: 0.5in;
  width: 2.5in;
}

.from p,
.for p {
  color: #787878;
}

.signature {
  padding-top: 0.5in;
}

.box-col {
  float: left;
}

.gridCard {
  background-color: #eaf7ff;
  display: flex;
  height: 100px;
  width: 90%;
  border-radius: 20px;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.gridCradText {
  margin-top: 10px;
  text-align: center;
  font-weight: 700;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: #fff;
  border: 0px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hover {
  cursor: pointer;
}

.noHover {
  cursor: default;
}

.tableViewEmployeeCard {
  display: flex;
  align-items: center;
  padding: 10px;
  height: 95%;
  border-radius: 10px;
  min-width: 200px;
  /* justify-content: flex?; */
  background-color: white;
}
.custom-height {
  max-height: 300px;
  overflow: auto;
}
.timeSheetIconView {
  height: 55px;
  width: 55px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 10px;
  background: #eef8fe;
  /* padding:10px */
}

.team-crd:nth-child(odd) {
  background-color: #e5f6fe;
}

.dashCard {
  background: #fff;
  min-width: 16em;
  max-width: 16em;
  border-radius: 0.5em;
  max-height: em;
  padding: 1em;
  box-shadow: rgba(229, 246, 254, 0.15) 0px 48px 100px 0px;
  margin: 1em;
  display: flex;
  flex-direction: column;
}

.dashCard .dashTitle {
  color: #333;
  padding: 0 0 0.5em 0;
  font-size: 15px;
  font-weight: bold;
  text-transform: capitalize;
  margin: 0 0 1em 0;
  border-bottom: 1px solid #f0f0f0;
  font-family: "Lexend Deca";
}

.dashCard .dashBody {
  display: flex;
  font-size: 4em;
}

.dashCard .icon {
  color: #c0c0c0;
  font-size: 30px;
}

.dashValue {
  font-weight: 100;
  font-size: 30px;
  margin-left: auto;
  color: #666;
}

.workingTimeCell:nth-child(odd) {
  background: #e5f6fe;
}

@media only screen and (max-width: 1450px) {
  .col-md-2-calendar {
    display: none;
  }

  .col-sm-calendar {
    display: block;
    width: 100% !important;
  }

  .calendar-row.bg-white {
    padding: 0px 0px;
  }

  .col-sm-calendar.date-active-2 {
    width: 100% !important;
  }
}

.ant-select-selection-item {
  padding-left: 16px !important;
}
.ant-table-cell-scrollbar {
  box-shadow: none;
}
.pt-new-2 {
  padding-top: 10px;
}
.ant-modal-content {
  border-radius: 10px;
}
.ant-breadcrumb-link {
  font-family: "Lexend Deca";
}

.red-border {
  border: 2px solid red !important;
}

.red-default {
  border: 2px solid #a1a9b3 !important;
}
.ant-select-item-option {
  padding-top: 10px;
  padding-bottom: 10px;
}
.type-button {
  border-width: 1px;
  justify-content: center;
  align-content: center;
  align-items: center;
  border-color: grey;
  display: flex;
  cursor: pointer;
  margin-right: 5px;
  height: 80px;
  width: 100px;
  border-radius: 5px;
}
.lexen-font {
  font-family: "Lexend Deca";
}
.Toastify__toast-body {
  font-family: "Lexend Deca";
  font-size: "fst-normal";
}
