.navigation {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.5rem 2rem;
  background-color: #fff;
  color: black;
  box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23);
}

.brand-name {
  text-decoration: none;
  color: black;
  font-size: 1.3rem;
  margin-left: 1rem;
}

.navigation-menu {
  margin-left: auto;
  z-index: 100;
}

.navigation-menu ul {
  display: flex;
  padding: 0;
  margin-bottom: 0;
}

.navigation-menu li {
  list-style-type: none;
  margin: 0 1rem;
}

.navigation-menu li a {
  text-decoration: none;
  /* display: block; */
  width: 100%;
}

.hamburger {
  border: 0;
  height: 40px;
  width: 40px;
  padding: 0.5rem;
  border-radius: 50%;
  background-color: #1c74c6;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  display: none;
}

.hamburger:hover {
  background-color: #0f64b3;
}

@media screen and (max-width: 768px) {
  .navigation-menu ul {
    display: none;
  }

  /* .navigation-menu li a {
    display: block;
  } */

  .hamburger {
    display: block;
  }

  .navigation-menu ul {
    position: absolute;
    top: 60px;
    left: 0;
    flex-direction: column;
    width: 100%;
    background-color: white;
    border-top: 1px solid black;
    padding: 20px 0;
  }

  .navigation-menu li {
    text-align: center;
    margin: 0;
  }

  .navigation-menu li a {
    color: #fff;
    padding: 0.47rem 15px;
  }

  .navigation-menu li:hover {
    background-color: #eee;
  }

  .navigation-menu ul {
    display: none;
  }

  .navigation-menu.expanded ul {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }
}

a.login-btn-cs {
  background-color: #00aff0;
  border: 2px solid transparent;
  color: #fff;
  padding: 8px 20px;
  border-radius: 4px;
  display: flex;
  gap: 2px;
  align-items: center;
  transition: 0.4s all;
}

a.login-btn-cs:hover {
  background-color: transparent;
  border: 2px solid #00aff0;
  color: #00aff0;
}

a.login-btn-cs:active {
  gap: 6px;
}

.font-bold {
  font-weight: 700;
}