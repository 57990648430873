.historyTimeline {
  position: relative;
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
  padding: 15px 0;
}

.historyTimeline::after {
  content: "";
  position: absolute;
  width: 5px;
  background: #e94b3c;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -2.5px;
}

.historyContainer {
  padding: 30px 60px;
  position: relative;
  background: inherit;
  width: 50%;
}

.historyContainer.left {
  left: 0;
}

.historyContainer.right {
  left: 50%;
}

.historyContainer .icon {
  position: absolute;
  display: inline-block;
  width: 60px;
  height: 60px;
  top: 30px;
  right: -30px;
  padding: 9px 0;
  background: #ffffff;
  border: 5px solid #e94b3c;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  color: #e94b3c;
  z-index: 1;
}

.historyContainer.right .icon {
  left: -30px;
}

.historyContainer .date {
  position: absolute;
  display: inline-block;
  height: 30px;
  min-width: 60px;
  top: 45px;
  padding: 6px 8px;
  background: #e94b3c;
  text-align: center;
  font-size: 14px;
  color: #ffffff;
  z-index: -1;
}

.historyContainer.left .date {
  right: -86px;
  border-radius: 0 30px 30px 0;
}

.historyContainer.right .date {
  left: -86px;
  border-radius: 30px 0 0 30px;
}

.historyContainer::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: 50px;
  right: 28px;
  z-index: 1;
  border: 10px solid;
  border-color: transparent #e94b3c transparent transparent;
}

.historyContainer.right::before {
  left: 28px;
  border-color: transparent transparent transparent #e94b3c;
}

.historyContainer .content {
  padding: 30px 45px;
  background: #ffffff;
  position: relative;
  border: 5px solid #eeeeee;
  border-radius: 500px 0 500px 500px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.historyContainer.right .content {
  border-radius: 0 500px 500px 500px;
}

.historyContainer .content h2 {
  margin: 0 0 10px 0;
  font-size: 18px;
  font-weight: normal;
  color: #e94b3c;
}

.historyContainer .content p {
  margin: 0;
  font-size: 16px;
  line-height: 22px;
  color: #454545;
}

.px-None {
  padding-right: 0;
  padding-left: 0;
}

.whosInCard {
  border-radius: 0.25rem;
  height: 100%;
  position: relative;
}

@media (max-width: 767.98px) {
  .historyTimeline::after {
    left: 90px;
  }

  .historyContainer {
    width: 100%;
    padding-left: 135px;
    padding-right: 30px;
  }

  .historyContainer.right {
    left: 0%;
  }

  .historyContainer.left .icon,
  .historyContainer.right .icon {
    width: 45px;
    height: 45px;
    top: 37.5px;
    font-size: 18px;
    left: 67.5px;
  }

  .historyContainer.left::before,
  .historyContainer.right::before {
    left: 110px;
    border-color: transparent transparent transparent #e94b3c;
  }

  .historyContainer.left .date,
  .historyContainer.right .date {
    right: auto;
    left: 15px;
    border-radius: 30px 0 0 30px;
  }

  .historyContainer.left .content,
  .historyContainer.right .content {
    border-radius: 0 500px 500px 500px;
  }
}
.ant-timeline-item {
  padding-bottom: 0px;
}
